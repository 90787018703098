<template>
  <app-select v-on="$listeners" v-bind="$attrs" hide-details="auto" dense :items="positions" />
</template>

<script>
export default {
  data() {
    return {
      positions: [
        { text: "Vendedor", value: "vendedor" },
        { text: "Gerente", value: "gerente" },
        { text: "Caixa", value: "caixa" },
        { text: "Auxiliar administrativo", value: "auxiliar_administrativo" },
        { text: "Estoquista", value: "estoquista" },
        { text: "Comprador", value: "comprador" },
        { text: "Motorista", value: "motorista" },
        { text: "Entregador", value: "entregador" },
        { text: "Cobrador", value: "cobrador" },
        { text: "Outros", value: "outros" },
        { text: "Logistica", value: "logistica" },
      ],
    };
  },
};
</script>

<style></style>