<template>
  <div>
    <v-row class="mt-4 mb-4">
      <v-col cols="12" md="4">
        <company-select solo flat background-color="grey lighten-4" v-model="form.company_id"
          :item-value="(val) => val.id" :itemText="(val) => val.short_name" clearable multiple />
      </v-col>
      <v-col cols="12" md="2">
        <app-date-field solo flat background-color="grey lighten-4" v-model="form.due_date_start"
          label="Data de vencimento Inicial" />
      </v-col>
      <v-col cols="12" md="2">
        <app-date-field solo flat background-color="grey lighten-4" v-model="form.due_date_end"
          label="Data de vencimento Final" />
      </v-col>
      <v-col>
        <v-btn color="primary" @click="select"> Pesquisar </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <app-select v-model="form.payment_term_id" :items="paymentTerms" :item-text="itemText" :item-value="itemValue"
          label="Forma de Pgto." solo flat background-color="grey lighten-4" :dense="false" @change="select()"
          clearable />
      </v-col>
      <v-col cols="12" md="4">
        <SalesmanSearch @input="setSalesman($event)" solo flat background-color="grey lighten-4" :dense="false"
          clearable />
      </v-col>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Códio</th>
            <th>Cliente</th>
            <th class="text-right">Valor</th>
            <th class="text-right">Pago</th>
            <th class="text-right">A Pagar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td><strong>Total</strong></td>
            <td></td>
            <td></td>
            <td class="text-right">
              <strong>{{ $format.decimal(total_sum_to_pay) }}</strong>
            </td>
          </tr>
          <tr v-for="billReceivable in billReceivables" :key="billReceivable.id">
            <td>
              {{ billReceivable.customer.code }}
            </td>
            <td @click="redirectToBillReceivableIndex(billReceivable.customer)">
              {{ billReceivable.customer.name }}
            </td>
            <td class="text-right">
              {{ $format.decimal(billReceivable.sum_value) }}
            </td>
            <td class="text-right">
              {{ $format.decimal(billReceivable.sum_paid_value) }}
            </td>
            <td class="text-right">
              {{ $format.decimal(billReceivable.sum_to_pay) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import CompanySelect from "@/components/company/ui/CompanySelect.vue";
import SalesmanSearch from "@/components/employees/ui/SalesmanSearch";

export default {
  components: {
    CompanySelect,
    SalesmanSearch
  },


  props: {
    itemText: {
      default() {
        return (val) => `${val.name}`;
      },
    },

    itemValue: {
      default() {
        return (val) => val.id;
      },
    },
  },

  data: () => ({
    billReceivables: [],

    paymentTerms: [],

    form: {
      issue_date_start: null,
      issue_date_end: null,
      due_date_start: null,
      due_date_end: null,
    },
  }),

  computed: {
    total_sum_to_pay() {
      return this.$calc.sum(this.billReceivables, "sum_to_pay");
    },
  },

  async mounted() {
    await this.loadPaymentTerms();
  },

  methods: {
    select() {
      this.$loading.start();
      this.$http
        .index("bill-receivable/report-by-customer", this.form)
        .then((response) => {
          this.billReceivables = response.billReceivables;
          this.$loading.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$loading.finish();
        });
    },

    redirectToBillReceivableIndex(customer) {
      window.open(
        "/contas-a-receber?customer_id=" +
        customer.id +
        "&customer_name=" +
        customer.name,
        "_blank"
      );
    },

    async loadPaymentTerms() {
      await this.$http.index("settings/payment-term", { status: 1 }).then((response) => {
        this.paymentTerms = response.payment_terms;

      });
    },

    setSalesman(salesman) {
      this.form.salesman_id = salesman.id;
      this.select();
    },
  },
};
</script>

<style></style>
